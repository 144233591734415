import styled from "styled-components";

const MainContainer = styled.div`
  text-align: center;
  background-color: #efefef;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const ImageDescriptionContainer = styled.div`
  display: flex;
  width: 100%;
`;

const Image = styled.img<{ mobile: boolean | undefined }>`
  width: ${({ mobile }) => (mobile ? "100vw" : "50vw")};
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export {
  ContentContainer,
  DescriptionContainer,
  MainContainer,
  Image,
  ImageDescriptionContainer
};
