import React from "react";

import wine1 from "app/utils/assets/wine_01.jpg";
import wine2 from "app/utils/assets/wine_02.jpg";
import wine3 from "app/utils/assets/wine_03.jpg";

import { EHomepageMessages } from "../../types";

import { ContentContainer, MainContainer } from "./styles";
import { ProductCart } from "app/components";

const WineGallerySection: React.FC = () => {
  return (
    <>
      <MainContainer>
        <ContentContainer>
          <ProductCart
            image={wine1}
            title={EHomepageMessages.WINE_GALLERY_WINE1_TITLE}
            subtitle={EHomepageMessages.WINE_GALLERY_WINE1_SUBTITLE}
            description={EHomepageMessages.WINE_GALLERY_WINE1_DESCRIPTION}
          />

          <ProductCart
            image={wine2}
            title={EHomepageMessages.WINE_GALLERY_WINE2_TITLE}
            subtitle={EHomepageMessages.WINE_GALLERY_WINE2_SUBTITLE}
            description={EHomepageMessages.WINE_GALLERY_WINE2_DESCRIPTION}
          />

          <ProductCart
            image={wine3}
            title={EHomepageMessages.WINE_GALLERY_WINE3_TITLE}
            subtitle={EHomepageMessages.WINE_GALLERY_WINE3_SUBTITLE}
            description={EHomepageMessages.WINE_GALLERY_WINE3_DESCRIPTION}
          />
        </ContentContainer>
      </MainContainer>
    </>
  );
};

export default WineGallerySection;
