import React, { useContext } from "react";

import bottle1 from "app/utils/assets/bottle_01.jpg";
import { Subtitle, Paragraph } from "app/pages/styles";
import {
  colors,
  WaterdropIcon,
  LeafIcon,
  WineGlassIcon
} from "app/utils/theme";
import { EHomepageMessages } from "../../types";

import {
  ContentContainer,
  FeaturesContainer,
  MainContainer,
  Image,
  ImageContainer
} from "./styles";
import { LayoutContext } from "app/context";

const {
  DARK,
  GRAY: { LIGHT_GRAY }
} = colors;

const AboutWinesSection: React.FC = () => {
  const { mobile } = useContext(LayoutContext);

  if (mobile) return null;

  return (
    <>
      <MainContainer>
        <Subtitle fontColor={DARK}>
          {EHomepageMessages.ABOUT_WINES_TITLE}
        </Subtitle>
        <ContentContainer>
          <ImageContainer>
            <Image src={bottle1}></Image>
          </ImageContainer>
          <FeaturesContainer>
            <WaterdropIcon />
            <Paragraph
              fontColor={LIGHT_GRAY}
              style={{ width: "60%", marginTop: "25px" }}
            >
              {EHomepageMessages.ABOUT_WINES_PARAGRAPH1}
            </Paragraph>
            <LeafIcon style={{ marginTop: "40px" }} />
            <Paragraph
              fontColor={LIGHT_GRAY}
              style={{ width: "60%", marginTop: "25px" }}
            >
              {EHomepageMessages.ABOUT_WINES_PARAGRAPH2}
            </Paragraph>
            <WineGlassIcon style={{ marginTop: "40px" }} />
            <Paragraph
              fontColor={LIGHT_GRAY}
              style={{ width: "60%", marginTop: "25px" }}
            >
              {EHomepageMessages.ABOUT_WINES_PARAGRAPH3}
            </Paragraph>
          </FeaturesContainer>
        </ContentContainer>
      </MainContainer>
    </>
  );
};

export default AboutWinesSection;
