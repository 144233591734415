import styled from "styled-components";

const BlackMask = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
`;

const TextSkeleton = styled.p`
  z-index: 1;
  margin: 10px 20px;
  text-align: center;
`;

const Title = styled(TextSkeleton)<{ fontColor: string }>`
  color: ${({ fontColor }) => fontColor};
  font-size: 70px;
  font-weight: 700;

  @media (max-width: 600px) {
    font-size: 40px;
  }
`;

const Subtitle = styled(TextSkeleton)<{ fontColor: string }>`
  color: ${({ fontColor }) => fontColor};
  font-size: 50px;
  font-weight: 700;

  @media (max-width: 600px) {
    font-size: 30px;
  }
`;

const SubSubtitle1 = styled(TextSkeleton)<{ fontColor: string }>`
  color: ${({ fontColor }) => fontColor};
  font-size: 30px;
  font-weight: 700;

  @media (max-width: 600px) {
    font-size: 15px;
  }
`;

const SubSubtitle2 = styled(TextSkeleton)<{ fontColor: string }>`
  color: ${({ fontColor }) => fontColor};
  font-size: 30px;
  line-height: 1.5;

  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const Paragraph = styled(TextSkeleton)<{ fontColor: string }>`
  color: ${({ fontColor }) => fontColor};
  font-size: 20px;

  @media (max-width: 600px) {
    font-size: 15px;
  }
`;

export { BlackMask, Paragraph, Title, Subtitle, SubSubtitle1, SubSubtitle2 };
