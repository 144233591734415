import { CSSProperties } from "react";

export type IconProps = {
  height?: string;
  color?: string;
  style?: CSSProperties;
};

export type IButtonTypes = {
  primary: string;
  secondary: string;
  danger: string;
  success: string;
};

export enum EButtonTypes {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  DANGER = "danger",
  SUCCESS = "success"
}
