import styled from "styled-components";

import { colors } from "app/utils/theme";

const {
  DARK,
  GRAY: { LIGHT_GRAY },
  WHITE
} = colors;

const MainContainer = styled.div`
  position: fixed;
  width: 100%;
  z-index: 200;
`;

const BarContainer = styled.div<{ isInTop: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  height: 50px;
  color: ${DARK};
  box-shadow: ${({ isInTop }) =>
    isInTop ? "none" : `0px 1px 10px 1px ${DARK}`};
  background-color: ${({ isInTop }) => (isInTop ? "none" : WHITE)};
  transition: background-color 0.5s, box-shadow 0.5s;
`;

const ToggleSidebarButton = styled.button`
  background: none;
  border: none;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`;

const ShoppingCartContainer = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    color: ${LIGHT_GRAY};
  }
`;

const ShoppingCartQtyContainer = styled.div`
  margin-left: 10px;
`;

const LogoImageContainer = styled.img`
  max-width: 80%;
  max-height: 80%;
`;

export {
  BarContainer,
  LogoImageContainer,
  MainContainer,
  ShoppingCartContainer,
  ShoppingCartQtyContainer,
  ToggleSidebarButton
};
