import styled from "styled-components";

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const TitleSectionContainer = styled.div<{ backgroundImage: string }>`
  background: url("${({ backgroundImage }) => backgroundImage}") 
    no-repeat center center;
  background-size: cover;
  top: 0;
  left: 0;
  height: 100vh;
  min-width: 100%;
  min-height: 100%;
`;

export { TitleContainer, TitleSectionContainer };
