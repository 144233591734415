import React from "react";

import { IProps } from "./types";
import { MainContainer } from "./styles";
import { colors } from "app/utils/theme";

const { DARK } = colors;

const Button = ({ onClick, text, type, style }: IProps) => {
  const backgroundColor = DARK;

  return (
    <MainContainer
      onClick={onClick}
      backgroundColor={backgroundColor}
      style={style}
    >
      {text}
    </MainContainer>
  );
};

export default Button;
