import React, { createContext, useState, useEffect, ReactNode } from "react";

import { IProps } from "./types";

const LayoutContext = createContext({
  mobile: undefined
} as IProps);

const LayoutContextProvider = LayoutContext.Provider;

const _LayoutContextProvider = ({ children }: { children: ReactNode }) => {
  const [mobile, setMobile] = useState<boolean | undefined>(undefined);

  const handleResize = () => {
    if (window.innerWidth <= 600) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    handleResize();
  }, []);

  if (mobile === undefined) return <p>Loading...</p>;

  return (
    <LayoutContextProvider value={{ mobile }}>{children}</LayoutContextProvider>
  );
};

export { LayoutContext, _LayoutContextProvider as LayoutContextProvider };
