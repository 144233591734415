import { lighten, darken } from "polished";

const colors = {
  BLUE: {
    DEFAULT_BLUE: "#0066ff",
    DARK_BLUE: darken(0.2, "#0066ff"),
    LIGHT_BLUE: lighten(0.4, "#0066ff")
  },
  DARK: "#000",
  GRAY: {
    DARK_GRAY: "#333333",
    GRAY: lighten(0.2, "#333333"),
    LIGHT_GRAY: lighten(0.4, "#333333"),
    EXTRA_LIGHT_GRAY: "#fafafa"
  },
  WHITE: "#fff"
};

export default colors;
