import React from "react";

import { LayoutContextProvider } from "./context";
import { Footer, HomePage } from "./pages";
import { Navbar } from "./components";

const App: React.FC = () => {
  return (
    <LayoutContextProvider>
      <Navbar />
      <HomePage />
      <Footer />
    </LayoutContextProvider>
  );
};

export default App;
