import React, { useState, useContext } from "react";

import Dialog from "../Dialog";
import Button from "../Button";
import { EButtonTypes } from "app/utils/types";

import { IProps } from "./types";
import {
  ImageContainer,
  MainContainer,
  Title,
  Description,
  DescriptionContainer,
  DetailsImageContainer
} from "./styles";
import { LayoutContext } from "app/context";

const ProductCard = ({ image, title, subtitle, description }: IProps) => {
  const { mobile } = useContext(LayoutContext);

  const [showDetailsDialog, setShowDetailsDialog] = useState<boolean>(false);

  return (
    <>
      <MainContainer>
        <ImageContainer src={image} />

        <Title>{title}</Title>

        <DescriptionContainer>
          <Description>{subtitle}</Description>
        </DescriptionContainer>

        <Button
          type={EButtonTypes.PRIMARY}
          text="Show details"
          onClick={() => {
            setShowDetailsDialog(true);
          }}
        ></Button>
      </MainContainer>

      {showDetailsDialog && (
        <Dialog
          title={title}
          content={
            <div style={{ width: mobile ? "90%" : "60%" }}>
              {!mobile && <DetailsImageContainer src={image} />}
              <p style={{ margin: !mobile ? 0 : "15px 0px" }}>{description}</p>
            </div>
          }
          confirmAction={{
            title: "Close",
            action: () => {
              setShowDetailsDialog(false);
            }
          }}
        ></Dialog>
      )}
    </>
  );
};

export default ProductCard;
