import styled from "styled-components";

const MainContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  z-index: 10;
  width: 40vw;
  height: 50vh;

  @media (max-width: 600px) {
    width: 70vw;
    height: 60vh;
  }
`;

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.5;
`;

const TitleContainer = styled.h1`
  font-size: 30px;
  margin-bottom: 20px;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 15px 0 0 0;
`;

export {
  Background,
  ButtonsContainer,
  CardContainer,
  ContentContainer,
  MainContainer,
  TitleContainer
};
