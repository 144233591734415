import React from "react";
import { createPortal } from "react-dom";

import Button from "../Button";
import { EButtonTypes } from "app/utils/types";

import { IProps } from "./types";
import {
  Background,
  ButtonsContainer,
  CardContainer,
  ContentContainer,
  MainContainer,
  TitleContainer
} from "./styles";

const Dialog = ({ title, content, confirmAction }: IProps) => {
  const {
    title: confirmActionTitle,
    action: confirmActionFunc
  } = confirmAction;

  return createPortal(
    <MainContainer>
      <CardContainer>
        <TitleContainer>{title}</TitleContainer>
        <ContentContainer>{content}</ContentContainer>
        <ButtonsContainer>
          <Button
            onClick={confirmActionFunc}
            text={confirmActionTitle}
            type={EButtonTypes.PRIMARY}
          />
        </ButtonsContainer>
      </CardContainer>
      <Background onClick={confirmActionFunc} />
    </MainContainer>,

    document.body
  );
};

export default Dialog;
