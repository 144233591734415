import styled from "styled-components";

import { colors } from "app/utils/theme";

const {
  GRAY: { EXTRA_LIGHT_GRAY }
} = colors;

const MainContainer = styled.div`
  padding: 100px 50px;
  text-align: center;
  background-color: ${EXTRA_LIGHT_GRAY};
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 30px 0;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

export { ContentContainer, MainContainer };
