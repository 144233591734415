import React from "react";

import { IconProps } from "app/utils/types";
import { colors } from "../../";

const {
  GRAY: { LIGHT_GRAY }
} = colors;

const EmailIcon = ({
  color = LIGHT_GRAY,
  height = "80px",
  style
}: IconProps) => {
  return (
    <svg fill={color} height={height} style={style} viewBox="0 0 512 512">
      <g>
        <g>
          <g>
            <path
              d="M486.4,59.733H25.6c-14.138,0-25.6,11.461-25.6,25.6v341.333c0,14.138,11.461,25.6,25.6,25.6h460.8
				c14.138,0,25.6-11.461,25.6-25.6V85.333C512,71.195,500.539,59.733,486.4,59.733z M494.933,426.667
				c0,4.713-3.82,8.533-8.533,8.533H25.6c-4.713,0-8.533-3.82-8.533-8.533V85.333c0-4.713,3.82-8.533,8.533-8.533h460.8
				c4.713,0,8.533,3.82,8.533,8.533V426.667z"
            />
            <path
              d="M470.076,93.898c-2.255-0.197-4.496,0.51-6.229,1.966L266.982,261.239c-6.349,5.337-15.616,5.337-21.965,0L48.154,95.863
				c-2.335-1.96-5.539-2.526-8.404-1.484c-2.865,1.042-4.957,3.534-5.487,6.537s0.582,6.06,2.917,8.02l196.864,165.367
				c12.688,10.683,31.224,10.683,43.913,0L474.82,108.937c1.734-1.455,2.818-3.539,3.015-5.794c0.197-2.255-0.51-4.496-1.966-6.229
				C474.415,95.179,472.331,94.095,470.076,93.898z"
            />
            <path
              d="M164.124,273.13c-3.021-0.674-6.169,0.34-8.229,2.65l-119.467,128c-2.162,2.214-2.956,5.426-2.074,8.392
				c0.882,2.967,3.301,5.223,6.321,5.897c3.021,0.674,6.169-0.34,8.229-2.65l119.467-128c2.162-2.214,2.956-5.426,2.074-8.392
				C169.563,276.061,167.145,273.804,164.124,273.13z"
            />
            <path
              d="M356.105,275.78c-2.059-2.31-5.208-3.324-8.229-2.65c-3.021,0.674-5.439,2.931-6.321,5.897
				c-0.882,2.967-0.088,6.178,2.074,8.392l119.467,128c3.24,3.318,8.536,3.442,11.927,0.278c3.391-3.164,3.635-8.456,0.549-11.918
				L356.105,275.78z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default EmailIcon;
