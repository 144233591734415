import React from "react";

import { Subtitle, Paragraph } from "app/pages/styles";
import { colors, EmailIcon, LocationIcon } from "app/utils/theme";
import { EHomepageMessages } from "../../types";

import { ContactWayContainer, ContentContainer, MainContainer } from "./styles";

const { DARK } = colors;

const ContactUsSection: React.FC = () => {
  return (
    <>
      <MainContainer>
        <Subtitle fontColor={DARK}>
          {EHomepageMessages.CONTACT_US_TITLE}
        </Subtitle>
        <ContentContainer>
          <ContactWayContainer>
            <EmailIcon style={{ marginBottom: "10px" }} />

            <Paragraph fontColor={DARK} style={{ fontSize: "15px" }}>
              {" "}
              {EHomepageMessages.CONTACT_US_EMAIL}
            </Paragraph>
          </ContactWayContainer>
          <ContactWayContainer>
            <LocationIcon style={{ marginBottom: "10px" }} />

            <Paragraph fontColor={DARK} style={{ fontSize: "15px" }}>
              {" "}
              {EHomepageMessages.CONTACT_US_ADDRESS}
            </Paragraph>
          </ContactWayContainer>
        </ContentContainer>
      </MainContainer>
    </>
  );
};

export default ContactUsSection;
