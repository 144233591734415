import styled from "styled-components";

import { colors } from "app/utils/theme";

const { WHITE } = colors;

const MainContainer = styled.div`
  padding: 100px 50px;
  text-align: center;
  background-color: ${WHITE};
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 80px 0 30px 0;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;

    margin: 40px 0 10px 0;
  }
`;

const ContactWayContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    margin-bottom: 40px;
  }
`;

export { ContactWayContainer, ContentContainer, MainContainer };
