import React, { useEffect, useState } from "react";

import logo from "app/utils/assets/logo1.jpg";

import {
  BarContainer,
  LogoImageContainer,
  MainContainer,
  ShoppingCartContainer,
  ShoppingCartQtyContainer
} from "./styles";

const Navbar = () => {
  const [isInTop, setIsInTop] = useState(true);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const _isInTop = window.scrollY < 100;

      if (_isInTop !== isInTop) {
        setIsInTop(_isInTop);
      }
    });
  });

  return (
    <MainContainer>
      <BarContainer isInTop={isInTop}>
        {!isInTop && (
          <>
            <LogoImageContainer src={logo} />
            <ShoppingCartContainer>
              <ShoppingCartQtyContainer>ABOLENGO</ShoppingCartQtyContainer>
            </ShoppingCartContainer>
          </>
        )}
      </BarContainer>
    </MainContainer>
  );
};

export default Navbar;
