import styled from "styled-components";

import { colors } from "app/utils/theme";

const {
  DARK,
  GRAY: { DARK_GRAY, LIGHT_GRAY }
} = colors;

const ImageContainer = styled.img`
  display: inline;
  max-height: 650px;
  max-width: 100%;
  cursor: pointer;
`;

const DetailsImageContainer = styled.img`
  height: 250px;
  width: 90px;
  flex: 0.5;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  padding: 0 0 20px 0;
  margin: 50px 10px;

  @media (max-width: 600px) {
    width: 90%;
  }
`;

const Title = styled.p`
  text-decoration: none;
  margin: 5px 0 15px 0;
  color: ${DARK};
  &:hover {
    color: ${DARK_GRAY};
  }
`;

const Description = styled.p`
  margin: 5px 10px 20px 10px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${LIGHT_GRAY};
  cursor: pointer;
`;

export {
  ImageContainer,
  MainContainer,
  Title,
  Description,
  DescriptionContainer,
  DetailsImageContainer
};
