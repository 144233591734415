import styled from "styled-components";

import { colors } from "app/utils/theme";

const { WHITE } = colors;

const MainContainer = styled.div`
  padding: 100px 50px;
  text-align: center;
  background-color: ${WHITE};
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
`;

export { ContentContainer, MainContainer };
