import React from "react";

import backgroundImage from "app/utils/assets/home_wallpaper.jpg";

import { BlackMask, Title, SubSubtitle2 } from "app/pages/styles";
import { colors } from "app/utils/theme";
import { EHomepageMessages } from "../../types";

import { TitleContainer, TitleSectionContainer } from "./styles";

const { WHITE } = colors;

const IntroSection: React.FC = () => {
  return (
    <>
      <TitleSectionContainer backgroundImage={backgroundImage}>
        <BlackMask />
        <TitleContainer>
          <Title fontColor={WHITE}>{EHomepageMessages.INTRO_TITLE}</Title>
          <SubSubtitle2 fontColor={WHITE}>
            {EHomepageMessages.INTRO_SUBTITLE}
          </SubSubtitle2>
        </TitleContainer>
      </TitleSectionContainer>
    </>
  );
};

export default IntroSection;
