import React, { useContext } from "react";

import { LayoutContext } from "app/context";
import wineYard1Img from "app/utils/assets/wineyard_01.jpg";
import wineYard2Img from "app/utils/assets/wineyard_02.jpg";
import { SubSubtitle1 as Subtitle, Paragraph } from "app/pages/styles";
import { colors } from "app/utils/theme";
import { EHomepageMessages } from "../../types";

import {
  ContentContainer,
  DescriptionContainer,
  MainContainer,
  Image,
  ImageDescriptionContainer
} from "./styles";

const {
  DARK,
  GRAY: { LIGHT_GRAY }
} = colors;

const AboutUsSection: React.FC = () => {
  const { mobile } = useContext(LayoutContext);

  return (
    <>
      <MainContainer>
        <ContentContainer>
          <ImageDescriptionContainer>
            <div style={{ flex: mobile ? "1" : "0.5" }}>
              <Image src={wineYard1Img} mobile={mobile} />
            </div>
            {!mobile && (
              <DescriptionContainer>
                <Subtitle fontColor={DARK}>
                  {EHomepageMessages.ABOUT_US_SUBTITLE1}
                </Subtitle>
                <Paragraph
                  fontColor={LIGHT_GRAY}
                  style={{ width: "60%", lineHeight: 1.5 }}
                >
                  {EHomepageMessages.ABOUT_US_PARAGRAPH1}
                </Paragraph>
              </DescriptionContainer>
            )}
          </ImageDescriptionContainer>
          <ImageDescriptionContainer>
            {!mobile && (
              <DescriptionContainer>
                <Subtitle fontColor={DARK}>
                  {EHomepageMessages.ABOUT_US_SUBTITLE2}
                </Subtitle>
                <Paragraph
                  fontColor={LIGHT_GRAY}
                  style={{ width: "60%", lineHeight: 1.5 }}
                >
                  {EHomepageMessages.ABOUT_US_PARAGRAPH2}
                </Paragraph>
              </DescriptionContainer>
            )}
            <div style={{ flex: mobile ? "1" : "0.5" }}>
              <Image src={wineYard2Img} mobile={mobile} />
            </div>
          </ImageDescriptionContainer>
        </ContentContainer>
      </MainContainer>
    </>
  );
};

export default AboutUsSection;
