import React from "react";

import {
  AboutUsSection,
  AboutWinesSection,
  ContactUsSection,
  IntroSection,
  OurFilosofySection,
  WineGallerySection
} from "./Sections";

const Home: React.FC = () => {
  return (
    <>
      <IntroSection />
      <OurFilosofySection />
      <AboutUsSection />
      <AboutWinesSection />
      <WineGallerySection />
      <ContactUsSection />
    </>
  );
};

export default Home;
