import React, { useContext } from "react";

import { Subtitle, SubSubtitle2 } from "app/pages/styles";
import { colors } from "app/utils/theme";
import { EHomepageMessages } from "../../types";

import { ContentContainer, MainContainer } from "./styles";
import { LayoutContext } from "app/context";

const {
  DARK,
  GRAY: { LIGHT_GRAY }
} = colors;

const OurFilosofySection: React.FC = () => {
  const { mobile } = useContext(LayoutContext);

  return (
    <>
      <MainContainer>
        <Subtitle fontColor={DARK}>
          {EHomepageMessages.OUR_FILOSOFY_TITLE}
        </Subtitle>
        <ContentContainer>
          <SubSubtitle2
            fontColor={LIGHT_GRAY}
            style={{ width: mobile ? "90%" : "40%" }}
          >
            {EHomepageMessages.OUR_FILOSOFY_SUBTITLE}
          </SubSubtitle2>
        </ContentContainer>
      </MainContainer>
    </>
  );
};

export default OurFilosofySection;
