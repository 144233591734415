import React from "react";

import logo from "app/utils/assets/logo2.jpg";
import { Paragraph } from "app/pages/styles";
import { colors } from "app/utils/theme";

import { MainContainer, LogoImageContainer } from "./styles";

const { WHITE } = colors;

const Footer: React.FC = () => {
  const copyrightDate = new Date();
  const copyrightYear = copyrightDate.getFullYear();

  return (
    <>
      <MainContainer>
        <LogoImageContainer src={logo} />
        <Paragraph fontColor={WHITE}>ABOLENGO</Paragraph>

        <Paragraph
          fontColor={WHITE}
          style={{ fontSize: "15px", margin: "30px 0 10px 0" }}
        >
          © {copyrightYear} The BB Wine Company, makers of Abolengo wines. All
          rights reserved.
        </Paragraph>

        <Paragraph
          fontColor={WHITE}
          style={{ fontSize: "15px", margin: "5px" }}
        >
          Developed by Acople.
        </Paragraph>
      </MainContainer>
    </>
  );
};

export default Footer;
