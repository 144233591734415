export enum EHomepageMessages {
  ABOUT_US_SUBTITLE1 = "Terroir.",
  ABOUT_US_SUBTITLE2 = "Inspiration.",
  ABOUT_US_PARAGRAPH1 = "The estate is in the renowned area of Peumo, famous for its high-quality red wines, where cooling winds from the sea bathe the vines, clean the air, and extend harvest time. Grapes growing on wonderful granite and clay soils mature at a gentle pace, developing thick, flavour-packed skins, before reaching near-perfect ripeness.",
  ABOUT_US_PARAGRAPH2 = "The Irarrázaval family have been supplying Chile’s leading wineries with high quality grapes for decades. Then in 2014, the family and other founders of Abolengo wines decided to bottle some of the magic of Peumo themselves, producing preciously small quantities of handcrafted wines for those in search of vinous treasure off the beaten track.",
  ABOUT_WINES_TITLE = "The inheritance of knowledge of a special terroir.",
  ABOUT_WINES_PARAGRAPH1 = "Abolengo is the expression of selective and careful winemaking – an experience that elevates the senses an appreciation of terroir.",
  ABOUT_WINES_PARAGRAPH2 = "Small-scale creation of wines respecting Peumo’s heritage in the Cachapoal Valley, shipped far and wide for the enjoyment of wine lovers.",
  ABOUT_WINES_PARAGRAPH3 = "Abolengo in it's three grape varieties providing character and elegance in every glass.",
  CONTACT_US_TITLE = "Contact us.",
  CONTACT_US_EMAIL = "hello@abolengowines.com",
  CONTACT_US_ADDRESS = "El Golf de Manquehue 9225. Santiago, Chile",
  INTRO_TITLE = "Abolengo.",
  INTRO_SUBTITLE = "From the noble Cachapoal Valley, the elegance of a unique terroir.",
  OUR_FILOSOFY_TITLE = "Impact.",
  OUR_FILOSOFY_SUBTITLE = "In 1890, on an estate in the Cachapoal Valley whose history goes back to the time of the Spanish Conquistadors, the Irarrázaval family began a tradition of breeding fine racehorses for the most prestigious racing in Chile. Where thoroughbreds were once raised prime vineyards now grow, whose grapes elegantly express the care and dedication given to them.",
  WINE_GALLERY_WINE1_TITLE = "Syrah and Carmenère.",
  WINE_GALLERY_WINE1_SUBTITLE = "D.O. Cachapoal, Peumo.",
  WINE_GALLERY_WINE1_DESCRIPTION = "Syrah - Carmenère, aged in French oak barrels for 12 months. Wonderful notes of red fruits, vanilla and coffee. A lot of elegance in the mouth makes this wine a pleasure to drink. Only 1500 bottles produced.",
  WINE_GALLERY_WINE2_TITLE = "Chardonnay.",
  WINE_GALLERY_WINE2_SUBTITLE = "D.O. Lontué.",
  WINE_GALLERY_WINE2_DESCRIPTION = "Elegant notes of vanilla and caramel, typical of a Chardonnay aged in French oak barrels (new and used). It has a great richness in the mouth and a long finish. Ideal to take with grilled fish, squid and seafood pasta.",
  WINE_GALLERY_WINE3_TITLE = "Carmenère and Syrah.",
  WINE_GALLERY_WINE3_SUBTITLE = "D.O. Cachapoal, Peumo.",
  WINE_GALLERY_WINE3_DESCRIPTION = "The perfect assembly, Carmenère-Syrah, which delivers the spicy Carmenere and the volume in the mouth of the Syrah. Very pleasant acidity and tannins that provide an elegant finish. Accompanies red meat and cheese very well."
}
