import React from "react";

import { IconProps } from "app/utils/types";
import { colors } from "../../";

const {
  GRAY: { LIGHT_GRAY }
} = colors;

const WineGlassIcon = ({
  color = LIGHT_GRAY,
  height = "80px",
  style
}: IconProps) => {
  return (
    <svg
      viewBox="-116 0 512 512.001"
      fill={color}
      height={height}
      style={style}
    >
      <path d="m0 200c0 66.542969 46.617188 122.898438 110 136.765625v119.054687l-74.472656 37.234376c-4.148438 2.074218-6.324219 6.730468-5.261719 11.242187 1.066406 4.515625 5.097656 7.703125 9.734375 7.703125h200c4.636719 0 8.667969-3.1875 9.730469-7.703125 1.066406-4.511719-1.109375-9.164063-5.261719-11.242187l-74.46875-37.234376v-119.054687c65.148438-14.257813 111.179688-72.804687 109.988281-138.957031-.417969-25.667969-4.417969-28.96875-40.242187-190.035156-1.035156-4.546876-5.082032-7.773438-9.746094-7.773438h-49c-5.523438 0-10 4.476562-10 10s4.476562 10 10 10h41.027344s36 158.121094 36.40625 160.625c-21.027344-10.335938-46.261719-19.976562-68.136719-20.621094-23.097656-.695312-44.21875 10.914063-64.628906 22.128906-19.34375 10.628907-37.609375 20.667969-54.066407 17.996094-13.59375-2.203125-30.175781-12.136718-48.171874-28.800781l34.546874-151.328125h43.023438c5.523438 0 10-4.476562 10-10s-4.476562-10-10-10h-51c-4.664062 0-8.710938 3.226562-9.75 7.773438-39.902344 174.914062-40.25 169.535156-40.25 192.226562zm197.640625 292h-115.28125l42.113281-21.054688c3.386719-1.695312 5.527344-5.160156 5.527344-8.945312v-122.367188c3.324219.234376 6.664062.367188 10 .367188s6.675781-.132812 10-.367188v122.367188c0 3.789062 2.140625 7.25 5.527344 8.945312zm-62.339844-292.339844c18.0625-9.921875 36.734375-20.1875 54.40625-19.664062 17.585938.515625 42.371094 8.96875 70.222657 23.882812-2.066407 62.835938-54.242188 116.652344-120.929688 116.109375-68.25-.550781-121.65625-58.394531-118.886719-125.015625 17.28125 14.140625 33.46875 22.496094 48.285157 24.898438 23.429687 3.804687 45.898437-8.667969 66.902343-20.210938zm0 0" />
      <path d="m151 10c0 5.523438-4.476562 10-10 10s-10-4.476562-10-10 4.476562-10 10-10 10 4.476562 10 10zm0 0" />
    </svg>
  );
};

export default WineGlassIcon;
