import styled from "styled-components";

import { colors } from "app/utils/theme";

const { DARK } = colors;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 50px;
  background-color: ${DARK};
`;

const LogoImageContainer = styled.img`
  width: 100px;
`;

export { LogoImageContainer, MainContainer };
