import styled from "styled-components";

import { colors } from "app/utils/theme";

const { WHITE } = colors;

const MainContainer = styled.div`
  padding: 100px 50px;
  text-align: center;
  background-color: ${WHITE};
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 30px 0;
`;

const Image = styled.img`
  height: 1000px;
  width: 400px;
  flex: 0.5;
`;

const ImageContainer = styled.div``;

const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0.5;
  align-items: center;
`;

export {
  ContentContainer,
  FeaturesContainer,
  Image,
  ImageContainer,
  MainContainer
};
