import React from "react";

import { IconProps } from "app/utils/types";
import { colors } from "../../";

const {
  GRAY: { LIGHT_GRAY }
} = colors;

const WaterdropIcon = ({
  color = LIGHT_GRAY,
  height = "80px",
  style
}: IconProps) => {
  return (
    <svg fill={color} height={height} style={style} viewBox="0 0 76.977 76.977">
      <g>
        <path
          d="M38.489,76.977c-15.54,0-28.183-12.643-28.183-28.182c0-14.53,23.185-44.307,25.828-47.654C36.703,0.421,37.571,0,38.488,0
		c0.917,0,1.785,0.42,2.354,1.141c2.644,3.348,25.828,33.124,25.828,47.654C66.671,64.334,54.029,76.977,38.489,76.977z
		 M38.489,7.917c-7.847,10.409-22.183,31.389-22.183,40.878c0,12.231,9.951,22.182,22.183,22.182s22.183-9.95,22.183-22.182
		C60.671,39.306,46.335,18.326,38.489,7.917z"
        />
        <path
          d="M38.489,64.981c-1.657,0-3-1.343-3-3s1.343-3,3-3c5.616,0,10.186-4.567,10.186-10.183c0-1.657,1.343-3,3-3
		c1.656,0,3,1.343,3,3C54.674,57.721,47.413,64.981,38.489,64.981z"
        />
      </g>
    </svg>
  );
};

export default WaterdropIcon;
